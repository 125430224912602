<template>
    <Transition
        :name="`expand-${direction}`"
        v-bind="$attrs"
        @before-enter="beforeEnter"
        @enter="enter"
        @after-enter="afterEnter"
        @before-leave="beforeLeave"
        @leave="leave"
        @after-leave="afterLeave"
    >
        <slot />
    </Transition>
</template>

<script>
const next = requestAnimationFrame;
export default {
    name: 'Expand',
    inheritAttrs: false,
    props: {
        direction: { type: String, default: 'vertical', options: ['vertical', 'horizontal'] },
        fade: { type: Boolean, default: false }
    },
    emits: ['expanded', 'closed'],
    slots: ['default'],
    computed: {
        attr() {
            return this.direction === 'vertical' ? 'height' : 'width';
        }
    },
    methods: {
        beforeEnter(el) {
            next(() => {
                if (!el.style[this.attr]) el.style[this.attr] = 0;
            });
        },
        enter(el) {
            next(() => next(() => {
                el.style[this.attr] = `${el[this.attr === 'height' ? 'scrollHeight' : 'scrollWidth']}px`;
            }));
        },
        afterEnter(el) {
            el.style[this.attr] = null;
            this.$emit('expanded');
        },
        beforeLeave(el) {
            next(() => {
                if (!el.style[this.attr]) el.style[this.attr] = `${el[this.attr === 'height' ? 'offsetHeight' : 'offsetWidth']}px`;
            });
        },
        leave(el) {
            next(() => next(() => {
                el.style[this.attr] = 0;
            }));
        },
        afterLeave(el) {
            el.style[this.attr] = null;
            this.$emit('closed');
        }
    }
};
</script>

<style lang="less" scoped>
.expand-vertical-enter-from, .expand-vertical-leave { will-change: height; }
.expand-horizontal-enter-from, .expand-horizontal-leave { will-change: width; }

.expand-vertical-enter-active, .expand-vertical-leave-active {
    transition: height 0.35s var(--curve);
    overflow: hidden;
}
.expand-horizontal-enter-active, .expand-horizontal-leave-active {
    transition: width 0.35s var(--curve);
    overflow: hidden;
}
</style>
